import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";

import {marked} from 'marked';

function SalesDataList(props) {
    const {products} = props;
    return (
        <div>
        <Accordion defaultActiveKey="0">
        {products && products.map((product, index) => (
                <Accordion.Item eventKey={index}>
                <Accordion.Header>{product.name}</Accordion.Header>
                <Accordion.Body>
                    <ul>
                        {product.performance_criteria && product.performance_criteria.map((item, index) => (
                            <li key={index}>
                                <h2>{item.criterion}</h2>
                                <span dangerouslySetInnerHTML={{__html: marked(item.performance_data)}}></span>
                            </li>
                        ))}
                    </ul>
                </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
        </div>
    );
}

export default SalesDataList;
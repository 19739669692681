import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { useForumList } from './common/ForumListContext';
import { clearLocalStorageItems } from './common/Functions';
import { useAuth0 } from "@auth0/auth0-react";
import { is } from "date-fns/locale";
import {ReactComponent as AskIcon} from '../images/v2/nav/ask.svg';
import {ReactComponent as CommunityIcon} from '../images/v2/nav/forum.svg';
import {ReactComponent as ChannelIcon} from '../images/v2/nav/channel.svg';
import {ReactComponent as KnowledgeIcon} from '../images/v2/nav/ks.svg';
import {ReactComponent as ResponseIcon} from '../images/v2/nav/response.svg';
import {ReactComponent as VoiceIcon} from '../images/v2/nav/voice.svg';
import {ReactComponent as ReportingIcon} from '../images/v2/nav/reporting.svg';
import {ReactComponent as UserIcon} from '../images/v2/nav/user.svg';
import {ReactComponent as BillingIcon} from '../images/v2/nav/billing.svg';
import {ReactComponent as QuestionIcon} from '../images/v2/nav/message-question.svg';

import {ReactComponent as AskIconHover} from '../images/v2/nav/white/ask.svg';
import {ReactComponent as CommunityIconHover} from '../images/v2/nav/white/forum.svg';
import {ReactComponent as ChannelIconHover} from '../images/v2/nav/white/channel.svg';
import {ReactComponent as KnowledgeIconHover} from '../images/v2/nav/white/ks.svg';
import {ReactComponent as ResponseIconHover} from '../images/v2/nav/white/response.svg';
import {ReactComponent as VoiceIconHover} from '../images/v2/nav/white/voice.svg';
import {ReactComponent as ReportingIconHover} from '../images/v2/nav/white/reporting.svg';
import {ReactComponent as UserIconHover} from '../images/v2/nav/white/user.svg';
import {ReactComponent as BillingIconHover} from '../images/v2/nav/white/billing.svg';
import {ReactComponent as QuestionIconHover} from '../images/v2/nav/white/message-question.svg';




function SideBarNav(props) {
    const { logout, user } = useAuth0();
    const location = useLocation();
     const { forumIds, selectedForumId, setSelectedForumId, setSelectForumName, selectForumName } = useForumList();
     const [isAdmin, setIsAdmin] = useState(false);

    const handleNavToggle = (e) => {
        e.preventDefault();
        props.setIsSidebarActive((prev) => !prev);
      };
      const handleSidebarNav = (e, forumId, forumName) => {
        e.preventDefault();
        setSelectedForumId(forumId);
        setSelectForumName(forumName);
      };
      useEffect(() => {
        // console.log('Updated selectedForumId:', selectedForumId);
        // Perform any additional actions here
      }, [selectedForumId]);
    useEffect(() => {
        if (user) {
            const roles = user?.ept_roles;
            if (roles && roles.includes('Admin')) {
                setIsAdmin(true);
            }
        }
      }, [user]);
      
  return (
    
    <div className="sidebar-nav">
        <div className="sidebar-inner">
            <a href="#" className="nav-toggle-btn" onClick={(e)=> handleNavToggle(e)}></a>
            <Link to="/" className="sidebar-logo">
                <span className="sidebar-logo-img"></span>
            </Link>
            
                <div className="nav-scroll">
                
                    <div className="upper-nav">
                        <div className="side-nav-heading">ept AI app channels</div>
                        <Link to="/" className={`ask-btn ${location.pathname === '/' ? 'active' : ''}`}>
                            <AskIcon className="sidebar-icons" />
                            <AskIconHover className="sidebar-icons-hover" />
                            <span>AI Chat</span>
                        </Link>
                        {selectedForumId && forumIds.length > 0 && (
                            <>
                        <Link to={`/community-forum/${forumIds[0].value}/page/1`} className={`community-btn ${location.pathname.startsWith('/community-forum') ? 'active' : ''}`}>
                            <CommunityIcon className="sidebar-icons" />
                            <CommunityIconHover className="sidebar-icons-hover" />
                            <span>Community Forum</span>
                        </Link>
                        <ul className={`sidebar_forum_list ${location.pathname.startsWith('/community-forum') ? 'open' : ''}`}>
                            {forumIds && forumIds.map((forum) => (
                                <li key={forum.value}>
                                    <Link
                                        to={`/community-forum/${forum.value}/page/1`}
                                        className={`forum-item ${forum.value === Number(selectedForumId) ? 'active' : ''}`}
                                        // onClick={(e) => handleSidebarNav(e, forum.value, forum.label)}
                                    >
                                        <span>{forum.label.replace(/(http|https|s:|\/)/g, '')}</span>
                                    </Link>
                                </li>
                            )
                            )}
                        </ul>
                        </>
                    )}
                    
                    </div>
                
                
                    {isAdmin && (
                        
                        
                    <div className="upper-nav">
                        <div className="side-nav-heading">AI Setup</div>
                        <Link to="/settings/channels" className={`channel-btn ${location.pathname === '/settings/channels' ? 'active' : ''}`}>
                            <ChannelIcon className="sidebar-icons" />
                            <ChannelIconHover className="sidebar-icons-hover" />
                            <span>Channel Management</span>
                        </Link>
                        <Link to="/settings/knowledge-sources" className={`ks-btn ${location.pathname.startsWith('/settings/knowledge-sources') ? 'active' : ''}`}>
                            <KnowledgeIcon className="sidebar-icons" />
                            <KnowledgeIconHover className="sidebar-icons-hover" />
                            <span>Knowledge Sources</span>
                        </Link>
                        <Link to="/settings/knowledge-source-configurations" className={` ksc-btn ${location.pathname.startsWith('/settings/knowledge-source-configurations') ? 'active' : ''}`}>
                            <KnowledgeIcon className="sidebar-icons" />
                            <KnowledgeIconHover className="sidebar-icons-hover" />
                            <span>Knowledge Source Configurations</span>
                        </Link>
                    </div>
                        
                    )}
                    <div className="upper-nav">
                        <div className="side-nav-heading">AI Performance Management</div>
                        <Link to="/responses" className={`response-btn ${location.pathname === '/responses' ? 'active' : ''}`}>
                            <ResponseIcon className="sidebar-icons" />
                            <ResponseIconHover className="sidebar-icons-hover" />
                            <span>Responses</span>
                        </Link>
                        {isAdmin && (
                            <>
                        <Link to="/question-type" className={`question-btn ${location.pathname === '/question-type' ? 'active' : ''}`}>
                            <QuestionIcon className="sidebar-icons" />
                            <QuestionIconHover className="sidebar-icons-hover" />
                            <span>Question Type</span>
                        </Link>
                        <Link to="/ai-voice" className={`voice-btn ${location.pathname === '/voice' ? 'active' : ''}`}>
                            <VoiceIcon className="sidebar-icons" />
                            <VoiceIconHover className="sidebar-icons-hover" />
                            <span>AI Voice</span>
                        </Link>
                        <Link to="/reporting" className={`analysis-btn ${location.pathname === '/analysis' ? 'active' : ''}`}>
                            <ReportingIcon className="sidebar-icons" />
                            <ReportingIconHover className="sidebar-icons-hover" />
                            <span>Reporting</span>
                        </Link>
                        </>
                        )}
                    </div>
                        
                    
                    
                    <div className="upper-nav">
                        <div className="side-nav-heading">Account Settings</div>
                        {isAdmin && (
                            <>
                        <Link to="/settings/user-management" className={`user-btn ${location.pathname === '/settings/user-management' ? 'active' : ''}`}>
                            <UserIcon className="sidebar-icons" />
                            <UserIconHover className="sidebar-icons-hover" />
                            <span>Users</span>
                        </Link>
                        <Link to="/settings/billing" className={`billing-btn ${location.pathname === '/settings/billing' ? 'active' : ''}`}>
                            <BillingIcon className="sidebar-icons" />
                            <BillingIconHover className="sidebar-icons-hover" />
                            <span>Billing & Subscription </span>
                        </Link>
                        </>
                        )}
                        <a className="logout-btn" href="#">
                     {user && (
                    <>
                        <img
                        className='profile-avatar'
                        src={user.picture}
                        alt={user.name}
                        />
                        <div className="sidebar-user">
                            {/* <span className='sidebar-user-text'>{user.name}</span> */}
                            <span className='sidebar-user-text'>{user.email}</span>
                        </div>
                    </>
                    )}
                    <span className="logout-after"  onClick={() => {
                      // check if itme exist in local storage
                      clearLocalStorageItems();
                      localStorage.removeItem('redirectPath');
                    logout({ logoutParams: { returnTo: window.location.origin } })
                    }}></span>
                    </a> 
                    </div>
                    
            </div>
            
            {/* <div className="lower-nav">
                <div className="nav-item">
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                </div>  
            </div> */}
        </div>
    </div>
    
    );
}

export default SideBarNav;
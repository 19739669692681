import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainAppWrap from "../layout/MainAppWrap"
import SimpleHeader from "../components/SimpleHeader";
import ResponseItemsList from "../components/responseList/ResponseItemsList";
// import ResponseListGrid from "../components/responseList/ResponseListGrid";

function ResponseList() {
    

  return (
    <MainAppWrap>
            <div className="main-content">
                <SimpleHeader />
                <Container fluid="xxl" className="">
                    <Row>
                        <Col className="settings-header">
                            <h1>Response List</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* <ResponseListGrid /> */}
                            <ResponseItemsList />
                        </Col>
                    </Row>
                </Container>
            </div>
        </MainAppWrap>
  );
}

export default ResponseList;
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SimpleHeader from "../components/SimpleHeader";
import MainAppWrap from "../layout/MainAppWrap";
import Select from "react-select";
import SalesViewData from "../components/salesGuide/SalesViewData";
import { useWebSocket } from "../components/common/WebsocketContext";
import LoaderWithText from "../components/common/LoaderWithText";
import { is } from "date-fns/locale";

function SalesGuide() {
    const { sendMessage, addListener, removeListener, webSocketStatus } = useWebSocket();
    const [productName, setProductName] = useState("Wi-Fi chip");
    const [application, setApplication] = useState("battery-operated smart home thermostat");
    const [competitor, setCompetitor] = useState({ value: "NXP", label: "NXP" });
    const [salesGuide, setSalesGuide] = useState({});
    const [isStreamingLoading, setIsStreamingLoading] = useState(false);
    const [messageSent, setMessageSent] = useState(false);



    const handleCompetitorChange = (selectedOption) => {
        setCompetitor(selectedOption);
      };

    const handleSubmit = () => {
        let message = {
            "action": "dynamic_report",
            "report_type": "sales_guide",
            "command": "write",
            "identifier": "1234",
            "competitor": competitor.value,
              "product_type": productName,
              "application": application,
        }
        console.log("Product Name: ", productName);
        console.log("Application: ", application);
        console.log("Competitor: ", competitor);
        if (webSocketStatus.current === 'open') {
            setMessageSent(true);
            sendMessage(JSON.stringify(message));
        } else {
            setTimeout(() => {
                handleSubmit();
            }, 5000);
        }
    }

        useEffect(() => {
            const handleMessage = (message) => {
                let data = JSON.parse(message);
                
                if(data.action === 'sales_guide' && data.identifier === '1234') {
                    setIsStreamingLoading(true);
                    
                    setSalesGuide((prevData) => {
                        // Helper function to add or update performance criteria for a specific product
                        const updateOrAddPerformanceData = (products = [], targetProductName) => {
                            return products.map((product) => {
                                if (product.name === targetProductName) {
                                    // Find if the performance criterion already exists for the product
                                    const existingCriterionIndex = product.performance_criteria?.findIndex(
                                        (criterion) => criterion.criterion === data.performance_criteria
                                    );
                    
                                    if (existingCriterionIndex !== -1 && product.performance_criteria) {
                                        // If criterion exists, update its performance_data by concatenating new data
                                        const updatedPerformanceCriteria = [...product.performance_criteria];
                                        updatedPerformanceCriteria[existingCriterionIndex] = {
                                            ...updatedPerformanceCriteria[existingCriterionIndex],
                                            performance_data: 
                                                (updatedPerformanceCriteria[existingCriterionIndex].performance_data || '') + data.performance_data
                                        };
                                        return { ...product, performance_criteria: updatedPerformanceCriteria };
                                    } else {
                                        // Add new criterion if it doesn't exist
                                        const newPerformanceCriteria = [
                                            ...(product.performance_criteria || []),
                                            { criterion: data.performance_criteria, performance_data: data.performance_data }
                                        ];
                                        return { ...product, performance_criteria: newPerformanceCriteria };
                                    }
                                }
                                return product;
                            });
                        };

                        return {
                            ...prevData,

                            // Initial load of company products
                            company_products: data.company_name !== competitor.value && data.company_products
                                ? [
                                    ...(prevData.company_products || []),
                                    ...data.company_products.map((product) => ({
                                        name: product,
                                        performance_criteria: []
                                    }))
                                ]
                                : data.product && data.company_name === "Infineon"
                                    ? updateOrAddPerformanceData(prevData.company_products, data.product)
                                    : prevData.company_products,

                            // Initial load of competitor products
                            competitor_products: data.company_name === competitor.value && data.company_products
                                ? [
                                    ...(prevData.competitor_products || []),
                                    ...data.company_products.map((product) => ({
                                        name: product,
                                        performance_criteria: []
                                    }))
                                ]
                                : data.product && data.company_name === competitor.value
                                    ? updateOrAddPerformanceData(prevData.competitor_products, data.product)
                                    : prevData.competitor_products,
                    
                            // Performance criteria array for standalone criteria if applicable
                            performance_criteria: data.performance_criteria && data.product === undefined ? (prevData.performance_criteria ? [...prevData.performance_criteria, ...data.performance_criteria] : data.performance_criteria) : prevData.performance_criteria,
                            
                            exec_summary: data.exec_summary || prevData.exec_summary,
                        };
                    });
                    
                    
                    }
                    
                
            };
            addListener(handleMessage);
    
            return () => {
                removeListener(handleMessage);
            };
        }, [addListener, removeListener]);
    
  return (
    <MainAppWrap>
      <div className="main-content">
        <SimpleHeader title="Sales Guide" />
        <Container fluid="xxl" className="sales-guide-page">
        {!messageSent && (
          <Row >
          <Col>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="channel-name">
                        Product Type:
                        <input
                          type="text"
                          className="form-control"
                          id="product-type"
                          name="product-type"
                          placeholder=""
                          value={productName}
                          onChange={(e) => setProductName(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-12">
                    <div className="input-group">
                      <label htmlFor="channel-name">
                        Application:
                        <input
                          type="text"
                          className="form-control"
                          id="application"
                          name="application"
                          placeholder=""
                          value={application}
                          onChange={(e) => setApplication(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <label>
                        {" "}
                        Select Competitor:
                        <Select
                          id="competitor"
                          onChange={handleCompetitorChange}
                          value={competitor}
                          className="form-select-new"
                          options={[
                            
                            { value: "NXP", label: "NXP" },
                          ]}
                          isSearchable
                        />
                      </label>
                    </div>
                  </div>
                  

                  <div className="ks-footer sales-guide-footer">
                    <a
                      href="#"
                      id="ks-submit-btn"
                      className="request_btn"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Generate
                    </a>
                  </div>

                </div>
              </Col>
          </Row>
            )}
          <Row>
            <Col>

            {messageSent && isStreamingLoading === false && (
                <LoaderWithText
                    text="Processing your request..."
                />
            )}
            {isStreamingLoading && (
                <LoaderWithText
                    text="Streaming sales guide data..."
                />
            )}
            </Col>
          </Row>
          <Row>
          {isStreamingLoading && (
            <SalesViewData 
                salesGuide={salesGuide}
                competitor={competitor}
             />
            )}
          </Row>
        </Container>

      </div>
    </MainAppWrap>
  );
}

export default SalesGuide;

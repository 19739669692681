import React, { useEffect, useState } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { se } from "date-fns/locale";
import CompaniesSections from "./CompaniesSections";
import {marked} from 'marked';


function SalesViewData(props){

    const {salesGuide, competitor} = props;

    const [performanceCritera, setPerformanceCritera] = useState([]);
    const [editCriteria, setEditCriteria] = useState("");
    const [editCriteriaIndex, setEditCriteriaIndex] = useState(null);
    const [exec_summary, setExecSummary] = useState("");

    // Start editing question
    const startEditingCriteria = (index) => {
        setEditCriteriaIndex(index);
        setEditCriteria(performanceCritera[index]);
    };
    // Cancel editing question
    const cancelCriteria = (index) => {
        setEditCriteriaIndex(null);
        setEditCriteria("");
    };
    // Save edited question
    const saveCriteria = (index) => {
        const items = Array.from(performanceCritera);
        items[index] = editCriteria;
        setPerformanceCritera(items);
        setEditCriteriaIndex(null);
        setEditCriteria("");
    };

    // Drag and drop handler for questions
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(performanceCritera);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPerformanceCritera(items);
  };

  useEffect(() => {
    console.log(salesGuide);

    salesGuide?.performance_criteria && setPerformanceCritera(salesGuide.performance_criteria);
    salesGuide?.exec_summary && setExecSummary(salesGuide.exec_summary);
    
    }, [salesGuide]);
    return (
        <div>
            <Row>
                <Col>
                <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                <Accordion.Header>Sales Guide</Accordion.Header>
                  <Accordion.Body>
                    <div dangerouslySetInnerHTML={{__html: marked(exec_summary)}}></div>
                  </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

            </Col>
            </Row>

            <Row>
                <Col>
                <h3>Performance Criteria:</h3>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="performance-criteria">
                    {(provided) => (
                        <ul className="gkd-list" {...provided.droppableProps} ref={provided.innerRef} style={{ padding: 0, listStyleType: "none" }}>
                        {performanceCritera?.map((item, index) => (
                            <Draggable key={index} draggableId={`${index}`} index={index}>
                            {(provided) => (
                                <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                    
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    ...provided.draggableProps.style,
                                }}
                                >
                                <span className="drag-icon"></span>
                                {editCriteriaIndex === index ? (
                                    <>
                                    <input
                                        type="text"
                                        placeholder="Type text..."
                                        value={editCriteria}
                                        onChange={(e) => setEditCriteria(e.target.value)}
                                        style={{ flex: 1, marginRight: "10px" }}
                                    />
                                    <button className="gkd-cross" onClick={() => cancelCriteria(index)}></button>
                                    <button className="gkd-check" onClick={() => saveCriteria(index)}></button>
                                    </>
                                ) : (
                                    <>
                                    <span style={{ flex: 1 }}>{item}</span>
                                    <div className="gkd-main-action-btn">
                                        <button className="gkd-edit-item" onClick={() => startEditingCriteria(index)} style={{ marginRight: "5px" }}>
                                        </button>
                                        <button className="gkd-delete-item" onClick={() => removeQCriteria(index)}></button>
                                    </div>
                                    </>
                                )}
                                </li>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </ul>
                    )}
                    </Droppable>
                </DragDropContext>
                </Col>
            </Row>

            <Row>
                <Col>
                    <CompaniesSections 
                        salesGuide={salesGuide}
                        competitor={competitor}
                     />
                </Col>
            </Row>
        </div>
    )
}

export default SalesViewData;